@use "custom";
@use './tailwind.css';
@use './assets/layout/layout.scss';
@use 'primeicons/primeicons.css';
@use './assets/demo/demo.scss';
// @import '~codemirror/lib/codemirror';
// @import '~codemirror/theme/material';
@import '../node_modules/codemirror/lib/codemirror.css';
@import '../node_modules/codemirror/addon/lint/lint.css';
@import '../node_modules/codemirror/addon/hint/show-hint.css';

// *{
//   //border: 1px solid red;
// }
// /* You can add global styles to this file, and also import other style files */
// html {
//   // font-size: 12px;
//   // font-family: 'Poppins', sans-serif;
//   // font-weight: 300;
//   // scrollbar-width: none;
//   // scroll-behavior: smooth;
//   // margin:0;
//   // padding: 0;
// }

// .p-menubar{
//   border: none;
// }
// //custom components
// .loader-container {
//   position: fixed; /* Covers the entire screen */
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   background-color: rgba(255, 255, 255, 0.8); /* Optional semi-transparent background */
//   z-index: 1000; /* Ensures it appears on top of other components */
// }

// .text-500-20px {
//   font-family: 'Poppins', sans-serif;
//   font-size: 20px;
//   font-weight: 500;
//   line-height: 30px;
//   text-align: left;
//   text-underline-position: from-font;
//   text-decoration-skip-ink: none;
//   //color: var(--BlueGray-BlueGray-900, #0F172A);
// }
// .text-500-24px {
//   font-family: 'Poppins', sans-serif;
//   font-size: 24px;
//   font-weight: 500;
//   line-height: 36px;
//   text-align: left;
//   text-underline-position: from-font;
//   text-decoration-skip-ink: none;
//   //color: var(--BlueGray-BlueGray-900, #334155);
// }
// .text-500-14px {
//   font-size: 14px;
//   font-weight: 500;
//   line-height: 21px;
//   text-align: left;
//   text-underline-position: from-font;
//   text-decoration-skip-ink: none;
//   //color: var(--bluegray-500, #334155);
// }
// .text-500-16px {
//   font-size: 12px;
//   font-weight: 500;
//   line-height: 18px;
//   text-align: left;
//   text-underline-position: from-font;
//   text-decoration-skip-ink: none;
//   //color: var(--bluegray-500, #64748B);
// }
// .generic-card {
//     display: flex;
//     flex-direction: column;
//     margin-top: 10px;
//     width: Fixed (1,111px)px;
//     height: Hug (200px)px;
//     padding: 24px 16px 24px 16px;
//     gap: 10px;
//     border-radius: 8px;
//     opacity: 0px;
//     //background: #F6F7FC;
//     border: 0.4px solid var(--Indigo-Indigo-300, #A5B4FC)
// }
// .search-bar {
//   width: 15vw;
// }
// .go-back {
//   cursor: pointer;
//   display: inline-flex;
//   flex-direction: row;
//   justify-content: center;
//   align-items: center;
//   width: Hug (88px)px;
//   height: Hug (29px)px;
//   top: 81px;
//   left: 24px;
//   padding: 4px 8px 4px 0px;
//   gap: 8px;
//   border-radius: 4px 0px 0px 0px;
//   opacity: 0px;
// }

// .error {
//     color: red;
// }
// body {
//   margin: 0;
//   padding: 0;
// }
// ::-webkit-scrollbar {
//   display: none;
// }

// .CodeMirror-hints {
//   position: absolute;
//   z-index: 1000;
//   background-color: #f0f0f0;
//   border: 1px solid #ccc;
//   padding: 2px;
//   font-size: 12px;
//   font-family: monospace;
//   list-style-type: none;
// }



