
//Mixin for pages
$sidebar-width: 20rem;
$sidemenu-width: 15rem;
$page-width: 64rem;
$navbar-height: 70px;
$top: 65px;

@mixin pages-reduced {
  width: calc(100% - $sidebar-width - $sidemenu-width);
  transform: translateX(calc($sidebar-width + $sidemenu-width ));
}

@mixin pages {
  margin: 4px;
  position: fixed;
  width: calc(99.50% - $sidemenu-width);
  transform: translateX($sidemenu-width );
  top: $top;
  height: calc(100vh - $navbar-height);
}

@mixin pages-no-sidemenu-full {
  margin: 4px;
  position: fixed;
  left: 0;
  width: 99.50%;
  top: $top;
  height: calc(100vh - $navbar-height);
}

@mixin pages-no-sidemenu {
  width: calc(100% - $sidebar-width);
  transform: translateX($sidebar-width);
}

.reduced-side-menu {
  top: calc($navbar-height + 2rem);
  left: $sidebar-width;
}
